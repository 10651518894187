const isLocalHost = false;
const path = isLocalHost ? "" : "/api";
console.log ("process.env.REACT_APP_API_ENDPOINT",process.env.REACT_APP_API_ENDPOINT);
export const URLS =
{
	BASE_URL: `${process.env.REACT_APP_API_ENDPOINT}/api/v1`,
	SOCKET_URL: `${process.env.REACT_APP_SOCKET_ENDPOINT}`,
	SOCKET_PATH: path +"/socket.io/",
	PIC_URL: `${process.env.REACT_APP_API_ENDPOINT}/profile_image/`,
	QR_URL: `${process.env.REACT_APP_API_ENDPOINT}/qr_codes/`,
}
